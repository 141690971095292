import PropTypes from "prop-types";
import React from "react";
import Markdown from "../Markdown";
import Link from "../Link";
import { Box, Heading, Flex } from "rebass/styled-components";

const HorizontalCard = ({ title, content, image, link, linklabel, imageOnRight }) => {
  imageOnRight = imageOnRight || false;

  const hasLinnk = link !== null && link !== "" && linklabel !== null && linklabel !== "";
  return (
    <Flex
      variant="horizontalCard"
      height="100%"
      alignItems="center"
      bg="background"
      flexWrap="wrap"
      flexDirection={["column", "column", !imageOnRight ? "row" : "row-reverse"]}
    >
      {image && (
        <>
          <Box
            width={[1, 1, 1 / 3]}
            py={4}
            mb={[4, 4, 0]}
            height={["300px", "300px", "100%"]}
            sx={{
              backgroundImage: `url(${
                typeof image === "string" ? image : image.childImageSharp.fluid.src
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "300px"
            }}
          ></Box>
          <Box variant="gridItem" width={[1, 1, 2 / 3]}>
            {title && <Heading as="h3">{title}</Heading>}
            {content && <Markdown>{content}</Markdown>}
            {hasLinnk && (
              <Box py={2} width={[1]}>
                <Link href={link}>{linklabel}</Link>
              </Box>
            )}
          </Box>
        </>
      )}
      {!image && (
        <Box variant="gridItem" width={[1]}>
          {title && <Heading as="h3">{title}</Heading>}
          {content && <Markdown>{content}</Markdown>}
          {hasLinnk && (
            <Box py={2} width={[1]}>
              <Link href={link}>{linklabel}</Link>
            </Box>
          )}
        </Box>
      )}
    </Flex>
  );
};

HorizontalCard.propTypes = {
  content: PropTypes.any,
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({
        src: PropTypes.any
      })
    })
  }),
  imageOnRight: PropTypes.bool,
  link: PropTypes.string,
  linklabel: PropTypes.string,
  title: PropTypes.any
};

export default HorizontalCard;
