import PropTypes from "prop-types";
import React from "react";
import HeaderPageComp from "../../UI/HeaderPage";

const HeaderPage = ({ data }) => {
  return <HeaderPageComp title={data.title} content={data.content} />;
};

HeaderPage.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.any,
    title: PropTypes.any
  })
};

export default HeaderPage;
