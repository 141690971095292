import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Grid from "../../UI/Grid";
import { Box, Text } from "rebass/styled-components";
import CustomImage from "../../UI/CustomImage";
import Markdown from "../../UI/Markdown";

const Features = ({ data }) => {
  data.items = data.items || [];
  return (
    <Wrapper data={data}>
      <Box
      py={3,3,6}
      sx={{
        position: "relative",
        "&:after": {
          content: "''",
          display: "block",
          width: "102vw",
          height: "100%",
          bg: "grayLight",
          position: "absolute",
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 1,
        },
        "& > *": {
          position: "relative",
          zIndex: 2,
        }
      }}>
        <Grid justifyContent="center" childWidth={[1, 1/2, 1/3, 1 / data.items.length]}>
          {data.items.map((item, i) => (
            <Box key={"num-" + i} textAlign="center" py={3,3,3}>
              <Text color="primary" fontSize={11} fontWeight="bold">{item.title}</Text>
              <Markdown>{item.content}</Markdown>
            </Box>
          ))}
        </Grid>
      </Box>
    </Wrapper>
  );
};

Features.propTypes = {
  data: PropTypes.shape({
    intro: PropTypes.any,
    items: PropTypes.array,
    title: PropTypes.any,
    content: PropTypes.any
  })
};
export default Features;

export const query = graphql`
  fragment FeaturesBlock on MarkdownRemarkFrontmatterBlockscollection {
    type
    items {
      title
      content
    }
  }
`;
