import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Grid from "../../UI/Grid";
import Markdown from "../../UI/Markdown";
import Fontello from "../../UI/Fontello";
import { Box, Heading, Flex, Text } from "rebass/styled-components";
import loadable from "@loadable/component";
import Forms from "../../Forms";

const Map = loadable(() => import("./Map"));

function isString(str) {
  if (str && typeof str.valueOf() === "string") {
    return true;
  }
  return false;
}

const FormMap = ({ data }) => {
  const hasData = data.form ? !isString(data.form) : false;
  const fluidContainer = data.blockSettings ? data.blockSettings.containerFluid : false;
  var telLink = "tel:+687";
  var mailTo = "mailto:";
  return (
    <Wrapper
      data={data}
      containerProps={{
        alignItems: "stretch",
        display: "flex !important",
        px: fluidContainer ? "0px !important" : ""
      }}
    >
      {hasData && (
        <Flex variant="gridLarge">
          <Box
          variant="gridLargeItem"
          alignItems="center"
          width={[1,1,1,1/2]}>
            <Forms data={data.form.frontmatter} />
          </Box>
          <Box
          variant="gridLargeItem"
          width={[1,1,1,1/2]}>
            {data.showInfo && (
              <Box
              sx={{
                "p:last-child":{m:0},
                "a": {
                  "&:hover": {
                    color: "primary"
                  }
                }
              }}>
                <Text variant="h3" textAlign={["left","left","left"]}>{data.cardTitle}</Text>                
                <Flex
                justifyContent={["flex-start","flex-start","flex-start"]}>
                  <Box>
                    <Flex mb={4}>
                      <Box mr={3} color="primary" fontSize={6}><Fontello icon="location" /></Box>
                      <Markdown>{data.address}</Markdown>
                    </Flex>
                    <a href={mailTo + data.email}>
                      <Flex alignItems="center" mb={4}>
                        <Box mr={3} color="primary" fontSize={6}><Fontello icon="mail" /></Box>
                        <Markdown>{data.email}</Markdown>
                      </Flex>
                    </a>
                    <a href={(telLink = telLink + data.phone.replace(/[- )(]/g, ""))}>
                      <Flex alignItems="center" mb={4}>
                        <Box mr={3} color="primary" fontSize={6}><Fontello icon="phone" /></Box>
                        <Markdown>{data.phone}</Markdown>
                      </Flex>
                    </a>
                    <Flex alignItems="center" mb={4}>
                      <Box mr={3} color="primary" fontSize={6}><Fontello icon="stopwatch" /></Box>
                      <Markdown>{data.opentime}</Markdown>
                    </Flex>
                  </Box>
                </Flex>    
              </Box>
            )}
            <Box height="350px" sx={{ position: "relative" }}>
              {data.googleMapLink && (
                <a href={data.googleMapLink} target="_blank">
                  <Map position={data.position} />
                </a>
              )}
              {!data.googleMapLink && (
                <Map position={data.position} />
              )}
            </Box>
          </Box>
        </Flex>
      )}
      {!hasData && (
        <Box>
          <Text>Formulaire + carte Non disponible en previsualisation</Text>
        </Box>
      )}
    </Wrapper>
  );
};

FormMap.propTypes = {
  data: PropTypes.shape({
    address: PropTypes.any,
    blockSettings: PropTypes.shape({
      containerFluid: PropTypes.any
    }),
    cardTitle: PropTypes.any,
    email: PropTypes.any,
    form: PropTypes.shape({
      frontmatter: PropTypes.any
    }),
    phone: PropTypes.any,
    position: PropTypes.any,
    showInfo: PropTypes.any
  })
};
export default FormMap;

export const query = graphql`
  fragment FormMapBlock on MarkdownRemarkFrontmatterBlockscollection {
    form {
      frontmatter {
        title
        formId
        successMessage
        elements {
          imputType
          label
          name
          printLabel
          placeholder
          required
          type
          default
          multiple
          options {
            value
            label
          }
        }
      }
    }
    position
    address
    cardTitle
    phone
    email
    opentime
    googleMapLink
    showInfo
  }
`;
