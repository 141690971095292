import PropTypes from "prop-types";
import React from "react";
import Header from "../Header";
import { Box } from "rebass/styled-components";
import Slider from "../../UI/Slider";

const HomeSlider = ({ data }) => {
  const slidesToShow = 1;
  var settings = {
    dots: true,
    autoplaySpeed: 2000,
    arrows: false,
    pauseOnHover: false,
    // pauseOnFocus: false,
    autoplaySpeed: 5000,
    infinite: true,
    autoplay: true,
    fade: true,
    speed: 800,
    slidesToShow: slidesToShow,
    slidesToScroll: 1
  };
  return (
    <Box
      sx={{
        ".slick-dots": {
          display: "flex !important",
          width: "100%",
          maxWidth: ["100%", "100%", "960px", "1200px"],
          left: "50%",
          marginLeft: [0, 0, "-480px", "-600px"],
          justifyContent: "flex-end",
          bottom: "100px",
          "li:before": { content: "unset !important" },
          "li button:before": {
            fontSize: "14px",
            color: "#fff"
          },
          li: {
            "&:hover,&.slick-active": {
              "button:before": { color: "primary" }
            }
          }
        }
      }}
    >
      <Slider {...settings}>
        {data.cards.map((card, i) => {
          let cardData = {
            blockSettings: { ...data.blockSettings, bgImg: card.image },
            bigTitle: card.title,
            ctas: [{ href: card.link, label: card.linklabel }]
          };
          return <Header key={i} data={cardData} />;
        })}
      </Slider>
    </Box>
  );
};

HomeSlider.propTypes = {
  slidesToShow: PropTypes.number,
  children: PropTypes.node
};

HomeSlider.defaultProps = {
  slidesToShow: 4
};
export default HomeSlider;
