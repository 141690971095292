import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";

import { Box, Flex, Heading } from "rebass/styled-components";
import CustomImage from "./CustomImage";
import Fontello from "./Fontello";

const AccordionItem = ({ i, expanded, setExpanded, children, title, icon }) => {
  const isOpen = i === expanded;
  const accRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (accRef !== null) {
      setHeight(accRef.current.clientHeight);
      if (i === expanded && typeof window !== "undefined") {
        setTimeout(function() {
          let headerOffset = 230;
          let elementPosition = accRef.current.getBoundingClientRect().top;
          let offsetPosition = window.pageYOffset + elementPosition - headerOffset;
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
          });
        }, 300);
      }
    }
  }, [accRef, expanded]);
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      if (accRef !== null) {
        setHeight(accRef.current.clientHeight);
      }
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return (
    <>
      <Box sx={{ overflow: "hidden" }}>
        <Box
          sx={{
            zIndex: 1,
            position: "relative",
            cursor: "pointer",
            borderWidth: "1px 0 0 0",
            borderStyle: "solid",
            borderColor: "primaryLight",
            py: 6,
            px: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            bg: isOpen ? "grayLight" : "transparent",
            "i:before": {
              transform: !isOpen ? "rotate(0deg)" : "rotate(-180deg)",
              transition: "300ms all ease"
            }
          }}
          initial={false}
          onClick={() => setExpanded(isOpen ? false : i)}
        >
          <Flex alignItems="center">
            {icon && <CustomImage height="45px" width="45px" minWidth="45px" img={icon} mr={3} />}
            <Heading as="h3" variant="cardTitle" textAlign="left">
              {title}
            </Heading>
          </Flex>
          <Box color="primary" fontSize={8}>
            <Fontello icon="chevron-down" />
          </Box>
        </Box>
        <Box
          sx={{
            zIndex: 0,
            position: "relative",
            height: isOpen ? height : 0,
            transition: "300ms height ease",
            // opacity: isOpen ? 1 : 0,
            bg: isOpen ? "grayLight" : "transparent"
          }}
        >
          <Box
            ref={accRef}
            sx={{
              opacity: isOpen ? 1 : 0,
              height: isOpen ? "auto" : 0,
              transition: "300ms all ease"
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </>
  );
};

AccordionItem.propTypes = {
  children: PropTypes.any,
  expanded: PropTypes.any,
  i: PropTypes.any,
  setExpanded: PropTypes.func,
  title: PropTypes.any
};

const Accordion = ({ items }) => {
  // This approach is if you only want max one section open at a time. If you want multiple
  // sections to potentially be open simultaneously, they can all be given their own `useState`.
  const [expanded, setExpanded] = useState("");
  return (
    <Box>
      {items.map((item, i) => {
        return (
          <AccordionItem
            key={i}
            i={i}
            expanded={expanded}
            setExpanded={setExpanded}
            title={item.title}
            icon={item.icon}
          >
            {item.content}
          </AccordionItem>
        );
      })}
    </Box>
  );
};

Accordion.propTypes = {
  items: PropTypes.array
};
export default Accordion;
