import { graphql } from "gatsby";
import ImgMarkupTwoCol  from "./ImgMarkupTwoCol"

export default ImgMarkupTwoCol;

export const query = graphql`
  fragment ImgMarkupTwoColBlock on MarkdownRemarkFrontmatterBlockscollection {
    id
    title
    image {
      publicURL
      childImageSharp {
        fluid(maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    content
    cta {
      label
      href
    }
    textPosition
  }
`;
