import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import { Box, Flex, Heading, Text } from "rebass/styled-components";
import Markdown from "../../UI/Markdown";

const Job = ({ data }) => {
  data.items = data.items || [];
  return (
    <Wrapper data={data} py={[0,0,0]}>
      {data.items.map((item, i) => (
        <Box
        display={["block","block","flex"]}
        key={"job-" + i}
        pt={[6,6,6]}
        mt={[6,6,6]}
        sx={{
          borderTop: "1px solid",
          borderColor: "primaryLight"
        }}>
            
            <Box maxWidth="200px">
              <Heading as="h2" variant="h3">{item.ref}</Heading>
            </Box>
            
            <Box sx={{flex:["none","none",1]}} pl={[0,0,6]}>
              {item.intro && (
                <Markdown>{item.intro}</Markdown>
              )}
              {item.title && (
                <Heading as="h3" variant="h4">{item.title}</Heading>
              )}
              {item.mission && (
                <Box>
                  <Heading as="h4" variant="h5">Mission</Heading>
                  <Markdown>{item.mission}</Markdown>
                </Box>
              )}
              {item.profile && (
                <Box>
                  <Heading as="h4" variant="h5">Profil recherché</Heading>
                  <Markdown>{item.profile}</Markdown>
                </Box>
              )}
              {item.skills && (
                <Box>
                  <Heading as="h4" variant="h5">Compétences / Expériences souhaitées</Heading>
                  <Markdown>{item.skills}</Markdown>
                </Box>
              )}
              {item.outro && (
                <Markdown>{item.outro}</Markdown>
              )}
            </Box>

        </Box>
      ))}
    </Wrapper>
  );
};

Job.propTypes = {
  data: PropTypes.shape({
    intro: PropTypes.any,
    outro: PropTypes.any,
    title: PropTypes.any,
    ref: PropTypes.any,
    mission: PropTypes.any,
    profile: PropTypes.any,
    skills: PropTypes.any,
  })
};
export default Job;

export const query = graphql`
  fragment JobBlock on MarkdownRemarkFrontmatterBlockscollection {
    items {
      title
      ref
      mission
      profile
      skills
      intro
      outro
    }
  }
`;
