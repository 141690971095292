import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import CustomImage from "../../UI/CustomImage";
import Wrapper from "../Wrapper";
import { Box } from "rebass/styled-components";
import loadable from "@loadable/component";
const Slider = loadable(() => import("../../UI/Slider"));

const Carousel = ({ data }) => {
  data.carouselItems = data.carouselItems || [];
  var settings = {
    dots: false,
    autoplaySpeed: 2000,
    arrows: false,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: data.carouselItems.length > 4 ? 4 : data.carouselItems.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: data.carouselItems.length > 3 ? 3 : data.carouselItems.length,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: data.carouselItems.length > 1 ? 1 : data.carouselItems.length,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <Wrapper data={data}>
      <Slider {...settings}>
        {data.carouselItems.map((carouselItem, i) => (
          <Box p="15px" key={"feature-" + i}>
            <h4>{carouselItem.name}</h4>
            <Box>
              <CustomImage img={carouselItem.image} />
            </Box>
          </Box>
        ))}
      </Slider>
    </Wrapper>
  );
};

Carousel.propTypes = {
  data: PropTypes.shape({
    carouselItems: PropTypes.array
  })
};

Carousel.defaultProps = {
  data: {
    carouselItems: []
  }
};
export default Carousel;
export const query = graphql`
  fragment CarouselBlock on MarkdownRemarkFrontmatterBlockscollection {
    content
    carouselItems {
      name
      image {
        publicURL
        childImageSharp {
          fluid(maxWidth: 300, quality: 64) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
