import PropTypes from "prop-types";
import React from "react";
import Markdown from "../Markdown";
import Link from "../Link";
import { Box, Heading, Flex } from "rebass/styled-components";
import CustomImage from "../CustomImage";

const VerticalCard = ({ title, content, image, link, linklabel }) => {
  const hasLinnk = link !== null && link !== "" && linklabel !== null && linklabel !== "";
  return (
    <Box variant="verticalCard" height="100%" bg="background">
      {image && (
        <>
          <Box mb={2} maxWidth="100px" mx="auto">
            <CustomImage height="auto" width="100%" img={image} alt={title} />
          </Box>
          <Box variant="gridItem" width={1}>
            {title && <Heading as="h3" variant="cardTitle">{title}</Heading>}
            {content && <Box pt={2}><Markdown>{content}</Markdown></Box>}
            {hasLinnk && (
              <Box py={2} width={[1]}>
                <Link href={link}>{linklabel}</Link>
              </Box>
            )}
          </Box>
        </>
      )}
      {!image && (
        <Box variant="gridItem" width={[1]}>
          {title && <Heading as="h3" variant="cardTitle">{title}</Heading>}
          {content && <Markdown>{content}</Markdown>}
          {hasLinnk && (
            <Box py={2} width={[1]}>
              <Link href={link}>{linklabel}</Link>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

VerticalCard.propTypes = {
  content: PropTypes.any,
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({
        src: PropTypes.any
      })
    })
  }),
  link: PropTypes.string,
  linklabel: PropTypes.string,
  title: PropTypes.any
};

export default VerticalCard;
