import PropTypes from "prop-types";
import React from "react";
import Wrapper from "../Wrapper";
import Markdown from "../../UI/Markdown";
import CustomImage from "../../UI/CustomImage";
import { Box, Heading, Flex, Button } from "rebass/styled-components";
import Link from "../../UI/Link";

const ImgMarkupTwoCol = ({ data }) => {
  return (
    <Wrapper data={data}>
      <Box px={[0,0,0,11]}>
        <Flex
        variant="grid"
        alignItems="center"
        flex="1"
        flexDirection={
          (data.textPosition == "left" && ["column-reverse", "column-reverse", "row"]) ||
          (data.textPosition == "right" && ["column-reverse", "column-reverse", "row-reverse"])
        }>
          <Box
          width={[1, 1, 1 / 2]}
          variant="gridItem"
          px={[3,3,6,11]}>
            <Box pt={[6,6, 0]}>
              {data.title && (
                <Box
                sx={{
                  "h3:after": {
                    height: "3px"
                  }
                }}>
                  <Heading as="h3" variant="h2" fontSize={8}>
                    {data.title}
                  </Heading>
                </Box>
              )}
              <Box
              sx={{
                "& > div > ul:last-child": {
                  mb: 0,
                  "li:last-child": {
                    mb: 0
                  }
                },
                "p:last-child": {
                    mb:0
                }
              }}>
                <Markdown>{data.content}</Markdown>
              </Box>
              {data.cta && (
                <Box mt={6} mr={9}>
                  <Link href={data.cta.href}>
                      <Button variant="link">
                      {data.cta.label}
                      </Button>
                  </Link>
                </Box>
              )}
            </Box>
          </Box>
          <Box width={[1, 1, 1 / 2]} variant="gridItem" px={[3,11,6,11]}>
            <Box
            sx={{
              ".gatsby-image-wrapper": {
                maxHeight: "auto"
              }
            }}>
              <CustomImage img={data.image} ml="auto" mr="auto" />
            </Box>
          </Box>
        </Flex>
      </Box>
    </Wrapper>
  );
};

ImgMarkupTwoCol.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.any,
    image: PropTypes.any,
    content: PropTypes.any,
    cta: PropTypes.any,
    textPosition: PropTypes.any
  })
};
export default ImgMarkupTwoCol;
