import PropTypes from "prop-types";
import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import Markdown from "../UI/Markdown";
import { Label, Input, Select, Textarea, Radio, Checkbox } from "@rebass/forms/styled-components";
import { Box, Flex, Text, Button } from "rebass/styled-components";
import loadable from "@loadable/component";

const Dropzone = loadable(() => import("react-dropzone-uploader"));
// const ReCAPTCHA = loadable(() => import("react-google-recaptcha"));

const addZero = i => {
  return i < 10 ? "0" + i : i;
};

function nowFormated(str, utc) {
  utc = utc || false;
  var d = new Date();
  var elements = {};
  elements.HH = addZero(utc ? d.getUTCHours() : d.getHours());
  elements.mm = addZero(d.getMinutes());
  elements.ss = addZero(d.getSeconds());
  elements.MM = addZero((utc ? d.getUTCMonth() : d.getMonth()) + 1);
  elements.DD = addZero(utc ? d.getUTCDate() : d.getDate());
  elements.YYYY = addZero(utc ? d.getUTCFullYear() : d.getFullYear());
  for (const [key, value] of Object.entries(elements)) {
    str = str.replace(key, value);
  }
  return str;
}

export const encodeData = data => {
  const formData = new FormData();
  Object.keys(data).forEach(key => formData.append(key, data[key]));
  return formData;
};
const isValidEmail = email => {
  var emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return email.search(emailRegEx) !== -1;
};
const Form = forwardRef((props, ref) => {
  const defaultSendAction = (valueToSend, e) => {
    e.preventDefault();
    fetch("/", {
      method: "POST",
      body: encodeData(valueToSend)
    })
      .then(() => submitSuccess())
      .catch(error => alert(error));
  };
  const data = props.data || { formId: "", elements: [], successMessage: "" };
  const sendAction = props.sendAction || defaultSendAction;
  const [messages, setMessages] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [hasError, setHasError] = useState(false);
  const [itemsError, setItemsError] = useState([]);
  const [sent, setSent] = useState(false);
  const setDefaultValues = () => {
    if (data) {
      const formValuesTemp = {};
      data.elements.map(formElement => {
        if (
          formElement.type !== "markdown" &&
          formElement.type !== "submit" &&
          formElement.type !== "recaptcha"
        ) {
          formValuesTemp[formElement.name] = formElement.default || "";
        }
        return null;
      });
      setFormValues({ ...formValuesTemp });
    }
  };
  useEffect(() => {
    setDefaultValues();
  }, []);
  useImperativeHandle(ref, () => ({
    submitSuccess() {
      submitSuccess();
    }
  }));
  if (!props.data) {
    return (
      <Box>
        <Text>Formulaire Non disponible en previsualisation</Text>
      </Box>
    );
  }

  // let captchaRef = useRef(null);

  const hasRecaptcha = () => {
    return data.elements.find(element => element.type === "recaptcha" && element.default);
  };

  const hasEpay = () => {
    return data.elements.find(element => element.type === "Epaync");
  };
  const handleChange = (valueName, value) => {
    setFormValues({
      ...formValues,
      [valueName]: value
    });
    if (itemsError.indexOf(valueName) >= 0) {
      const newMessages = [...messages].filter(message => message.name !== valueName);
      var tempError = [...itemsError].filter(name => name !== valueName);
      var hasTempError = hasError;
      data.elements.map(function(formElement) {
        if (formElement.name === valueName) {
          var currentValue = value;
          if (formElement.required && currentValue === "" && formElement.type !== "recaptcha") {
            hasTempError = true;
            tempError.push(formElement.name);
            newMessages.push({
              name: formElement.name,
              message: 'Le champ "' + formElement.label + '" est requis.'
            });
          }
          if (formElement.type === "text") {
            if (formElement.imputType === "email") {
              if (!isValidEmail(currentValue)) {
                tempError.push(formElement.name);
                hasTempError = true;
                newMessages.push({ name: formElement.name, message: "Email non valide." });
              }
            }
          }
        }

        return "";
      });
      setMessages(newMessages);
      setHasError(hasTempError);
      setItemsError(tempError);
    }
  };

  const handleSubmit = e => {

    const newMessages = [];
    var tempError = [];
    var hasTempError = false;
    // const captchaValue = hasRecaptcha() ? captchaRef.current.getValue() : false;

    data.elements.map(function(formElement) {
      var currentValue = formValues[formElement.name] || "";
      if (formElement.required && currentValue === "" && formElement.type !== "recaptcha") {
        hasTempError = true;
        tempError.push(formElement.name);
        newMessages.push({
          name: formElement.name,
          message: 'Le champ "' + formElement.label + '" est requis.'
        });
      }
      if (formElement.type === "text") {
        if (formElement.imputType === "email") {
          if (!isValidEmail(currentValue)) {
            tempError.push(formElement.name);
            hasTempError = true;
            newMessages.push({ name: formElement.name, message: "Email non valide." });
          }
        }
      }
      return "";
    });
 const myForm = e.target;
  const formData = new FormData(myForm);
formData.forEach((value, key) => (formData[key] = value));
    if (!formData["g-recaptcha-response"] && hasRecaptcha()) {
      hasTempError = true;
      newMessages.push({
        name: "recaptcha",
        message: "Veuillez confirmer que n'êtes pas un robot"
      });
    }
    setMessages(newMessages);
    setHasError(hasTempError);
    setItemsError(tempError);
    if (!hasTempError) {
      const valueToSend = {
        "form-name": data.formId,
        ...formValues
      };
      if (hasRecaptcha()) {
        valueToSend["g-recaptcha-response"] =formData["g-recaptcha-response"];
      }
      sendAction(valueToSend, e);
    } else {
      e.preventDefault();
    }
  };
  const submitSuccess = () => {
    setFormValues({});
    if (data.successMessage) {
      setMessages([{ name: "", message: data.successMessage }]);
    }
    setSent(true);
    setTimeout(function() {
      setMessages([]);
      setSent(false);
    }, 3000);
  };
  const replaceTokens = text => {
    for (const id in formValues) {
      var re = new RegExp("{" + id + "}", "g");
      text = text.replace(re, formValues[id]);
    }
    return text;
  };
  // file
  const getUploadParams = () => {
    return { url: "https://httpbin.org/post" };
  };
  const handleChangeStatus = (fileWithMeta, status, filesWithMeta, valueName) => {
    if (status === "done" || status === "removed") {
      var fileList = [];
      if (status === "done") {
        fileList = filesWithMeta.map(files => files.file);
      }
      if (status === "removed") {
        fileList = filesWithMeta
          .map(files => files.file)
          .filter(file => file.name !== fileWithMeta.file.name);
      }
      const JSZip = require("jszip");
      const zip = new JSZip();
      if (fileList.length > 0) {
        fileList.forEach(file => zip.file(file.name, file));

        zip.generateAsync({ type: "blob" }).then(blob => {
          const zippedFiles = new File([blob], "fichiers.zip", {
            lastModified: Date.now(),
            type: "application/zip"
          });
          setFormValues({
            ...formValues,
            [valueName]: zippedFiles
          });
        });
      } else {
        setFormValues({
          ...formValues,
          [valueName]: null
        });
      }
    }
  };

  const epayFormPart = formElementInput => {
    if (window === "undefined") {
      return <></>;
    }
    const HmacSHA256 = require("crypto-js/hmac-sha256");
    const Base64 = require("crypto-js/enc-base64");
    const siteUrl =
      typeof window === "undefined" ? "" : window.location.protocol + "//" + window.location.host;

    const formElement = { ...formElementInput };
    formElement.vads_version = "V2";
    formElement.vads_trans_id = nowFormated("HHmmss");
    formElement.vads_action_mode = "INTERACTIVE";
    formElement.vads_trans_date = nowFormated("YYYYMMDDHHmmss", true);
    Object.keys(formElement)
      .filter(value => value.indexOf("vads_url") === 0)
      .map(value => {
        formElement[value] = formElement[value] || "/";
        if (formElement[value].indexOf("/") === 0) {
          formElement[value] = siteUrl + formElement[value];
        }
        return;
      });
    Object.keys(formElement)
      .filter(value => value.indexOf("vads_") === 0)
      .map(value => {
        formElement[value] = replaceTokens(formElement[value]);
        return;
      });
    const toSign =
      Object.keys(formElement)
        .filter(value => value.indexOf("vads_") === 0)
        .sort()
        .map(value => formElement[value])
        .join("+") +
      "+" +
      formElement.shakey;
    if (!props.raw) {
      const hashDigest = HmacSHA256(toSign, formElement.shakey);
      const hmacDigest = Base64.stringify(hashDigest);
      formElement.signature = hmacDigest;
    }

    return (
      <>
        {Object.keys(formElement)
          .filter(value => value.indexOf("vads_") === 0)
          .map((value, i) => (
            <input key={i} type="hidden" name={value} value={formElement[value]} />
          ))}
        <input type="hidden" name="signature" value={formElement.signature} />
      </>
    );
  };
  const formProps = hasRecaptcha()
    ? {
        "data-netlify": "true",
        "data-netlify-recaptcha": "true"
      }
    : {
        "data-netlify": "true"
      };
  if (hasEpay()) {
    formProps.action = "https://epaync.nc/vads-payment/";
    formProps.method = "post";
  }
  return (
    <Box>
      <form
        onSubmit={e => {
          handleSubmit(e);
        }}
        name={data.formId}
        id={data.formId}
        {...formProps}
      >
        <input type="hidden" name="form-name" aria-label="form-name" value={data.formId}></input>
        {!sent && (
          <Flex flexWrap="wrap" mx={-2} sx={{"&>div:last-child":{mb:0}}}>
            {data.elements.map((formElement, i) => {
              formElement.options = formElement.options || [{ value: "" }];
              formElement.width = formElement.width || "100%";
              formElement.width = formElement.width.replace(/\s/g, "");
              return (
                <Box
                  key={"form-el-" + i}
                  mb={5}
                  px={2}
                  width={formElement.width.split(",")}
                  variant={itemsError.indexOf(formElement.name) < 0 ? "" : "formItemError"}
                  display="flex"
                  alignItems="center"
                >
                  {formElement.type === "text" && (
                    <>
                      {formElement.label && formElement.printLabel && (
                        <Label htmlFor={formElement.name}>{formElement.label}</Label>
                      )}
                      <Input
                        id={formElement.name}
                        name={formElement.name}
                        defaultValue={formElement.default}
                        placeholder={formElement.placeholder}
                        onChange={e => {
                          handleChange(formElement.name, e.target.value);
                        }}
                      />
                    </>
                  )}
                  {formElement.type === "textarea" && (
                    <>
                      {formElement.label && formElement.printLabel && (
                        <Label htmlFor={formElement.name}>{formElement.label}</Label>
                      )}
                      <Textarea
                        id={formElement.name}
                        name={formElement.name}
                        defaultValue={formElement.default}
                        placeholder={formElement.placeholder}
                        rows="6"
                        onChange={e => {
                          handleChange(formElement.name, e.target.value);
                        }}
                      />
                    </>
                  )}
                  {formElement.type === "radio" && (
                    <Box
                      sx={{
                        "input:checked ~ .checkit": {
                          display: "none"
                        },
                        "input:checked ~ .checkit:nth-child(2)": {
                          display: "block"
                        }
                      }}
                    >
                      <Label htmlFor={formElement.name}>{formElement.label}</Label>
                      {formElement.options.map((option, iOption) => (
                        <Label key={iOption}>
                          <Radio
                            className="checkit"
                            id={formElement.name + "-" + iOption}
                            name={formElement.name}
                            value={option.value}
                            onChange={e => {
                              handleChange(
                                formElement.name,
                                e.target.checked ? e.target.value : ""
                              );
                            }}
                            defaultChecked={option.value === formElement.default}
                          />
                          <Box sx={{ lineHeight: 1.5, flex: 1 }}>
                            <Markdown sx={{ "p:first-child": { mt: 0 } }}>
                              {replaceTokens(option.label)}
                            </Markdown>
                          </Box>
                        </Label>
                      ))}
                    </Box>
                  )}
                  {formElement.type === "checkbox" && (
                    <Box
                      sx={{
                        "input:checked ~ .checkit": {
                          display: "none"
                        },
                        "input:checked ~ .checkit:nth-child(2)": {
                          display: "block"
                        }
                      }}
                    >
                      <Label htmlFor={formElement.name}>{formElement.label}</Label>
                      {formElement.options.map((option, iOption) => (
                        <Label key={iOption}>
                          <Checkbox
                            className="checkit"
                            id={formElement.name + "-" + iOption}
                            name={formElement.name}
                            value={option.value}
                            onChange={e => {
                              var currentValue = formValues[formElement.name] || "";
                              var currentValueArray = currentValue.split(",");
                              if (currentValueArray.indexOf(e.target.value) !== -1) {
                                currentValueArray.splice(
                                  currentValueArray.indexOf(e.target.value),
                                  1
                                );
                              }
                              if (e.target.checked) {
                                currentValueArray.push(e.target.value);
                              }
                              handleChange(
                                formElement.name,
                                currentValueArray.filter(v => v !== "").join(",")
                              );
                            }}
                            defaultChecked={option.value === formElement.default}
                            color="gray50"
                          />
                          <Box sx={{ lineHeight: 1.5, flex: 1 }}>
                            <Markdown sx={{ fontSize: 0,"p:first-child": { mt: 0 } }}>
                              {replaceTokens(option.label)}
                            </Markdown>
                          </Box>
                        </Label>
                      ))}
                    </Box>
                  )}
                  {formElement.type === "select" && (
                    <>
                      <Label htmlFor={formElement.name}>{formElement.label}</Label>
                      <Select
                        id={formElement.name}
                        name={formElement.name}
                        defaultValue={formElement.default}
                        onChange={e => {
                          handleChange(
                            formElement.name,
                            e.target.selectedOptions ? e.target.value : ""
                          );
                        }}
                      >
                        {formElement.options.map((optionObj, iOption) => (
                          <option key={iOption} value={optionObj.value}>
                            {optionObj.label}
                          </option>
                        ))}
                      </Select>
                    </>
                  )}
                  {formElement.type === "file" && (
                    <>
                      {formElement.label && (
                        <Label htmlFor={formElement.name}>
                          {formElement.required ? formElement.label + "*" : formElement.label}
                        </Label>
                      )}
                      <Box
                        sx={{
                          bg: "primaryLight",
                          borderStyle: "dashed",
                          borderWidth: "2px",
                          borderColor: "rgba(255,255,255,.3)",
                          ".dzu-input": { display: "none" },
                          ".dzu-inputLabel, .dzu-inputLabelWithFiles": {
                            py: 3,
                            px: 4,
                            display: "block",
                            cursor: "pointer"
                          },
                          ".dzu-inputLabelWithFiles": {
                            borderTopStyle: "dashed",
                            borderTopWidth: "2px",
                            borderTopColor: "rgba(255,255,255,.3)"
                          },
                          ".dzu-previewContainer": {
                            py: 3,
                            px: 4,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                            borderTopStyle: "dashed",
                            borderTopWidth: "2px",
                            borderTopColor: "rgba(255,255,255,.3)",
                            "&:first-child": { border: "none" },
                            "& > *:first-child": {
                              maxWidth: "50%"
                            },
                            "& > img:first-child": {
                              maxWidth: "30%"
                            },
                            ".dzu-previewStatusContainer": {
                              display: "flex",
                              alignItems: "center"
                            },
                            ".dzu-previewButton": {
                              backgroundSize: "14px 14px",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              width: "14px",
                              height: "14px",
                              cursor: "pointer",
                              ml: 2
                            }
                          },
                          ".dzu-submitButtonContainer": { display: "none" }
                        }}
                      >
                        <input
                          name={formElement.name}
                          multiple=""
                          type="file"
                          autoComplete="off"
                          tabIndex="-1"
                          style={{ display: "none" }}
                        ></input>
                        <Dropzone
                          getUploadParams={getUploadParams}
                          onChangeStatus={(fileWithMeta, status, filesWithMeta) =>
                            handleChangeStatus(
                              fileWithMeta,
                              status,
                              filesWithMeta,
                              formElement.name
                            )
                          }
                          maxFiles={formElement.maxFiles}
                          multiple={formElement.maxFiles > 1 ? true : false}
                          maxSizeBytes={formElement.maxSizeBytes * 1000000}
                          inputContent={formElement.placeholder}
                          inputWithFilesContent={formElement.placeholder}
                          submitButtonContent="Envoyer"
                          submitButtonDisabled={true}
                          name={formElement.name}
                        />
                      </Box>
                    </>
                  )}
                  {formElement.type === "recaptcha" && (

<div
      dangerouslySetInnerHTML={{__html: '<div data-netlify-recaptcha="true"></div>'}}
    />
                  )}
                  {formElement.type === "markdown" && (
                    <Box
                    sx={{
                      fontSize: 0,
                      "p:first-child": {
                        mt: 0
                      },
                      "p:last-child": {
                        mb: 0
                      }
                    }}>
                      <Markdown>{replaceTokens(formElement.default)}</Markdown>
                    </Box>
                  )}
                  {formElement.type === "Epaync" && epayFormPart(formElement)}
                  {formElement.type === "hidden" && (
                    <input type="hidden" name={formElement.name} value={formElement.default} />
                  )}
                  {formElement.type === "submit" && (
                    <Button type="submit" variant="gray" ml={[0,0,0,"auto"]}>
                      {formElement.label}
                    </Button>
                  )}
                </Box>
              );
            })}
          </Flex>
        )}
      </form>
      {messages.length > 0 && (
        <Box bg={hasError ? "danger" : "success"} p={3} color="white">
          {messages.map((message, index) => (
            <Text key={`contact-form-message-${index}`}>{message.message}</Text>
          ))}
        </Box>
      )}
    </Box>
  );
});

Form.propTypes = {
  data: PropTypes.shape({
    elements: PropTypes.array,
    formId: PropTypes.string,
    successMessage: PropTypes.string
  }),
  raw: PropTypes.bool,
  sendAction: PropTypes.any
};
export default Form;
