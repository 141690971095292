import HeroHeader2 from "./Variants/HeroHeader2";
import BannerJob from "./Variants/BannerJob";
import HeaderPage from "./Variants/HeaderPage";
import HomeSlider from "./Variants/HomeSlider";

const variants = {
  HeroHeader2: HeroHeader2,
  BannerJob: BannerJob,
  HeaderPage: HeaderPage,
  HomeSlider: HomeSlider
};

const BlockVariant = block => {
  if (block.blockSettings) {
    if (block.blockSettings.variant) {
      if (variants[block.blockSettings.variant]) {
        const VariantComp = variants[block.blockSettings.variant];
        if (VariantComp) {
          return VariantComp;
        }
      }
    }
  }
  return false;
};
export default BlockVariant;
