import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Markdown from "../../UI/Markdown";
import Wrapper from "../Wrapper";
import { Flex, Box, Heading, Button } from "rebass/styled-components";
import Link from "../../UI/Link";
import blockcollection from "../../../cms/data/settings/blockcollections.json";
import { withTheme } from "styled-components";

const Banner = ({ data, theme }) => {
  return (
    <Wrapper data={data}>
        <Box
        bg="grayLight"
        p={[9, 10, 11]}>
            <Flex
            flexDirection={["column","column","row"]}
            justifyContent="space-between"
            variant="grid">
                <Box width={[1,1,1,2/3]} variant="gridItem" sx={{"p:last-child":{mb:0}}}>
                <Flex flexDirection="column" justifyContent="center" height="100%">
                    {data.title && (
                    <Heading as="h2" variant="h2" my={0}>{data.title}</Heading>
                    )}
                    {data.content && (
                    <Box mt={3}>
                        <Markdown>{data.content}</Markdown>
                    </Box>
                    )}
                </Flex>
                </Box>
                <Flex mt={[4,4,4,0]} width={[1,1,1,1/3]} justifyContent={["flex-start","flex-start","flex-start","flex-end"]} alignItems="center" variant="gridItem">
                    <Link href={data.cta.href}>
                        <Button variant="gray">{data.cta.label}</Button>
                    </Link>
                </Flex>
            </Flex>
        </Box>
    </Wrapper>
  );
};

Banner.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.any,
    content: PropTypes.any,
    cta: PropTypes.any
  }),
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      gradiantBanner: PropTypes.any
    })
  })
};

export default withTheme(Banner);

