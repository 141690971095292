import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import { Flex, Box, Heading, Text } from "rebass/styled-components";
import CustomImage from "../../UI/CustomImage";
import Markdown from "../../UI/Markdown";

const Team = ({ data }) => {
  return (
    <Wrapper data={data}>
      <Flex variant="gridLarge">
        {data.items.map((item, i) => (
        <Box variant="gridLargeItem" key={"team-" + i} py={6} width={[1,1,1/2]}>
          <Box mb={4} mx="auto" maxWidth="200px">
            <CustomImage height="auto" width="100%" img={item.image} alt={item.title} />
          </Box>
          {item.title && <Heading as="h3" variant="h3" mb="0" textAlign="center">{item.title}</Heading>}
          {item.job && <Text color="primary" fontWeight="bold" fontSize={4} mt={1} textAlign="center">{item.job}</Text>}
          {item.content && <Box pt={3}><Markdown>{item.content}</Markdown></Box>}
        </Box>
        ))}
      </Flex>
    </Wrapper>
  );
};

Team.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.any,
  })
};
export default Team;

export const query = graphql`
  fragment TeamBlock on MarkdownRemarkFrontmatterBlockscollection {
    items {
      title
      content
      job      
      image {
        publicURL
        childImageSharp {
          fluid(maxWidth: 400, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
