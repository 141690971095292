import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Icofont from "../../UI/Icofont";
import { Box, Heading, Text, Button } from "rebass/styled-components";
import Link from "../../UI/Link";
import Fontello from "../../UI/Fontello";

const Header = ({ data }) => {
  const fullHeightActived = data.blockSettings ? data.blockSettings.fullHeight : false;
  const bgImg = data.blockSettings
    ? data.blockSettings.bgImg
      ? data.blockSettings.bgImg
      : ""
    : "" || "";
  data.ctas = data.ctas || [];

  const bgColor = data.blockSettings
    ? data.blockSettings.bg
      ? data.blockSettings.bg
      : false
    : false || false;
  const sx = {
    backgroundImage: `url(${typeof bgImg === "string" ? bgImg : bgImg.childImageSharp.fluid.src})`,
    position: "relative",
    ">*": {
      position: "relative",
      zIndex: 1
    }
  };
  if (bgColor) {
    sx[":before"] = {
      content: '""',
      position: "absolute",
      zIndex: 0,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: "100%",
      height: "100%",
      backgroundImage: "radial-gradient(circle, " + bgColor + "99 0%, " + bgColor + "99 100%)"
    };
  }
  const containerProps = {};
  const wrapperProps = {};
  if (fullHeightActived) {
    if (data.blockSettings.padding) {
      containerProps.pb = data.blockSettings.padding.bottom;
      containerProps.pt = data.blockSettings.padding.top;
    }
    wrapperProps.minHeight = "600px";
    wrapperProps.pb = 0;
    wrapperProps.pt = 0;
  }
  return (
    <Wrapper
      data={data}
      sx={sx}
      containerProps={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        ...containerProps
      }}
      display="flex"
      {...wrapperProps}
    >
      <Box py={(4, 4, 6)} px={(8, 8, 10)}>
        <Box
          mb={(4, 4, 6)}
          maxWidth="700px"
          sx={{
            position: "relative",
            "&:before": {
              content: "''",
              display: "block",
              width: "10px",
              height: "100%",
              borderRadius: "5px",
              bg: "primary",
              position: "absolute",
              left: [-8, -8, -10],
              top: 0
            }
          }}
        >
          <Heading as="h1" variant="h1" color="white">
            {data.bigTitle}
          </Heading>
        </Box>
        {data.subTitle && <Text color="white">{data.subTitle}</Text>}
        {data.ctas.map((cta, i) => (
          <Link href={cta.href} key={i}>
            <Button variant="white">{cta.label}</Button>
          </Link>
        ))}

        <Box
          sx={{
            position: "absolute",
            bottom: [65, 3],
            width: "50px",
            marginLeft: "-25px",
            textAlign: "center",
            left: "50%",
            // transform: "translateX(-50%)",
            "animation-duration": "1s",
            "animation-iteration-count": "infinite",
            "animation-name": "bounce-1",
            "animation-timing-function": "linear",
            "@keyframes bounce-1": {
              "0%": { transform: "translateY(0)" },
              "50%": { transform: "translateY(-10px)" },
              "100%": { transform: "translateY(0)" }
            },
            button: {
              color: "white",
              "&:hover": {
                color: "primary"
              }
            }
          }}
        >
          <Link href="#a-propos">
            <Button variant="ninja" fontSize={[8, 10]}>
              <Fontello icon="chevron-down" />
            </Button>
          </Link>
        </Box>
      </Box>
    </Wrapper>
  );
};

Header.propTypes = {
  data: PropTypes.shape({
    bigTitle: PropTypes.any,
    blockSettings: PropTypes.shape({
      bg: PropTypes.any,
      bgImg: PropTypes.any
    }),
    ctas: PropTypes.array,
    subTitle: PropTypes.any
  })
};

export default Header;
export const query = graphql`
  fragment HeaderBlock on MarkdownRemarkFrontmatterBlockscollection {
    content
    bigTitle
    subTitle
    ctas {
      href
      icon
      label
    }
  }
`;
