import React from "react";
import { graphql } from "gatsby";
import Page from "./Page";
import Blocks from "../components/Blocks";
import PropTypes from "prop-types";
export const PageRawTemlate = ({ data, lang }) => {
  return <Blocks blocks={data.blockscollection} lang={lang} />;
};
const PageTemplate = ({ data, pageContext }) => {
  const infos = {
    slug: data.markdownRemark.frontmatter.slug,
    title: data.markdownRemark.frontmatter.title,
    description: data.markdownRemark.frontmatter.description,
    blockscollection: data.markdownRemark.frontmatter.blockscollection,
    thumbnail: data.markdownRemark.frontmatter.thumbnail
      ? data.markdownRemark.frontmatter.thumbnail.publicURL
      : null
  };
  return (
    <Page title={infos.title} description={infos.description} thumbnail={infos.thumbnail} id={infos.slug} breadcrumb={pageContext.breadcrumb} customCrumbLabel={infos.title}>
      <PageRawTemlate data={infos} lang={pageContext.lang} />
    </Page>
  );
};
PageTemplate.propTypes = {
  data: PropTypes.object
};
PageRawTemlate.propTypes = {
  data: PropTypes.object
};
export default PageTemplate;

export const pageQuery = graphql`
  query PageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        slug
        title
        description
        thumbnail {
          publicURL
        }
        blockscollection {
          ...Blocks
        }
      }
    }
  }
`;
