import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { Button } from "rebass/styled-components";
import Icofont from "./Icofont";

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.3);
  z
`;
const OverlayClose = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: 100%;
  background: transparent;
`;
const ModalContainer = styled(motion.div)`
  width: auto;
  height: auto;
  background-color: transparent;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: scroll;
  max-height: 100%;
  max-width: 100%;
`;

const modalVariant = {
  initial: { opacity: 0 },
  isOpen: { opacity: 1 },
  exit: { opacity: 0 }
};
const containerVariant = {
  initial: { top: "60%", opacity: 0, transition: { type: "spring" } },
  isOpen: { top: "50%", opacity: 1 },
  exit: { top: "60%", opacity: 0 }
};
const Modal = ({ handleClose, children, isOpen, bypassBackgroundClose }) => {
  bypassBackgroundClose = bypassBackgroundClose || false;
  return (
    <AnimatePresence>
      {isOpen && (
        <Overlay initial={"initial"} animate={"isOpen"} exit={"exit"} variants={modalVariant}>
          {!bypassBackgroundClose && <OverlayClose onClick={handleClose} />}
          <ModalContainer variants={containerVariant}>
            <Button
              onClick={handleClose}
              variant="ninja"
              sx={{
                position: "absolute",
                top: "20px",
                right: "20px"
              }}
            >
              <Icofont icon="close" />
            </Button>
            {children}
          </ModalContainer>
        </Overlay>
      )}
    </AnimatePresence>
  );
};

Modal.propTypes = {
  children: PropTypes.any,
  handleClose: PropTypes.any,
  isOpen: PropTypes.any,
  bypassBackgroundClose: PropTypes.bool
};

export default Modal;
