import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../Wrapper";
import Grid from "../../UI/Grid";
import { Box, Text } from "rebass/styled-components";
import Markdown from "../../UI/Markdown";

const Markup = ({ data }) => {
  data.numberItem = data.numberItem || [];
  return (
    <Wrapper data={data}>
      <Grid childWidth={[1, 1 / 2, 1 / 3]}>
        {data.numberItem.map((item, i) => (
          <Box key={"num-" + i} textAlign="center">
            <Text fontSize={10} sx={{ display: "inline", position: "relative" }}>
              {item.number}
              <Text fontSize={2} sx={{ position: "absolute", top: "30px", left: "100%" }}>
                {item.exposant}
              </Text>
            </Text>

            <Markdown>{item.content}</Markdown>
          </Box>
        ))}
      </Grid>
    </Wrapper>
  );
};

Markup.propTypes = {
  data: PropTypes.shape({
    numberItem: PropTypes.array
  })
};
export default Markup;

export const query = graphql`
  fragment NumbersBlock on MarkdownRemarkFrontmatterBlockscollection {
    numberItem {
      number
      content
      exposant
    }
  }
`;
