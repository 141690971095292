import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Markdown from "../../UI/Markdown";
import Wrapper from "../Wrapper";
import { Heading, Box } from "rebass/styled-components";

const Markup = ({ data }) => {
  return (
    <Wrapper data={data}>
      {data.title && (
        <Box
        sx={{
          "h2:after":{
            mx:"auto"
          }
        }}>
          <Heading as="h2" variant="h2">{data.title}</Heading>
        </Box>
      )}
      <Markdown>{data.content}</Markdown>
    </Wrapper>
  );
};

Markup.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.any,
    title: PropTypes.any
  })
};
export default Markup;

export const query = graphql`
  fragment MarkupBlock on MarkdownRemarkFrontmatterBlockscollection {
    title
    content
  }
`;
