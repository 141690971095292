
import { graphql } from "gatsby";
import React from "react";
import PropTypes from "prop-types";
import CustomImage from "../../UI/CustomImage";
import Wrapper from "../Wrapper";
import { Box, Flex, Heading } from "rebass/styled-components";
import Accordion from "../../UI/Accordion";

const AccordionCustomer = ({ data }) => {
  return (
    <Wrapper data={data}>
        <Box>
          <Accordion
            items={data.sections.map((section, i) => {
              return {
                title: section.title,
                icon: section.icon,
                content: (
                  <Box key={"section-" + i} p={6} pt={0}>
                    <Flex variant="grid">
                      {section.items &&
                        section.items.map((item, index) => (
                          <Box
                            variant="gridItem"
                            width={[1 / 2, 1 / 3, 1 / 4, 1 / 6]}
                            pt={6}
                            key={index}
                          >
                            <Box
                              sx={{
                                p: 3,
                                boxShadow: "card",
                                bg: "white",
                                height: "100%",
                                // maxHeight: "80px",
                                div: {
                                  // maxHeight: "80px",
                                  width: "100%",
                                  "&.gatsby-image-wrapper": {
                                    width: "100%",
                                    height: "unset !important",
                                    // maxHeight: "unset",
                                    img: {
                                      objectFit: "contain !important"
                                    }
                                  }
                                }
                              }}
                            >
                              <Flex alignItems="center" justifyContent="center">
                                <CustomImage
                                  height="100%"
                                  width="auto"
                                  img={item.image}
                                  alt={section.title + index}
                                />
                              </Flex>
                            </Box>
                          </Box>
                        ))}
                    </Flex>
                  </Box>
                )
              };
            })}
          ></Accordion>
        </Box>
      </Wrapper>
  );
};

AccordionCustomer.propTypes = {
  data: PropTypes.shape({
    sections: PropTypes.any,
  })
};
export default AccordionCustomer;

export const query = graphql`
  fragment AccordionCustomerBlock on MarkdownRemarkFrontmatterBlockscollection {
    sections {
      title
      icon {
        publicURL
      }
      items {
        image {
          publicURL
          childImageSharp {
            fluid(maxHeight: 80, quality: 95) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
